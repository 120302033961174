$local-fonts: (
    "Larken" : (
        400 : "Larken",
        normal : "Larken-i"
    ),
    "GT-America" : (
        400 : "GT-America",
        500 : "GT-America-Med",
    ),
    "GT-America-Ex" : (
        500 : "GT-America-Ex-Med",
        700 : "GT-America-Ex-Bold"
    )
);

@import "~client_core/utilities/fonts";