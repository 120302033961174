.simple-hero-media {
    position: relative;
    height: 100%;
    width: 100%;
}

.simple-hero-img {
    width: 100%;
}
.simple-hero-container {
    display: flex;
    height: 100%;
    width: 100%;
}
